<template>
  <el-drawer :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
    :modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
    <div class="h-full flex flex-dc" v-if="isDrawer">
      <div class="drawer_title pall-30 flex flex-jb flex-n">
        <div class="fs-18 fw-bold">精品商品详情</div>
        <i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
      </div>
      <div class="flex-1 overflow-scroll-y">
        <t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="2" @handleEvent="handleEvent"
          class="drawer_form">
          <!-- 上传图片 -->
          <template #uploadFile="even">
            <upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
              iconName="el-icon-upload" iconSize="26" :fileNumber="even.data.fileNumber"
              v-model="formOpts.formData[even.value]"></upload-file>
          </template>
        </t-simple-form>
      </div>
      <div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
        <el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
        <el-button type="primary" plain @click="handleClose">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'rewardDetails',
  components: {
    "TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  data () {
    return {
      goodId: '',//详情id
      isDrawer: false,
      butLoading: false,
      formOpts: {
        labelPosition: 'top',
        ref: null,
        formData: {
          goodsName: '',	//商品名称
          shelves: '',	//是否上架
          price: '',	//原价
          soldPrice: '',	//售出价格
          deliverDate: '',//发货日期
          soldNum: '0',	//售出数量
          inventory: '100',	//库存
          note: '',  //玩法说明
          goodsImg: '',	//封面图
          detailsImg: '',	//详情图
          describeImg: [],	//描述图片
          sort: '',//排序
        },
        fieldList: [
          { label: '商品名称', value: 'goodsName', comp: 'el-input' },
          { label: '上下架售罄', value: 'shelves', type: 'select-arr', comp: 'el-select', list: 'shelves', arrLabel: 'key', arrKey: 'value' },
          { label: '分类', value: 'goodsMallType', type: 'select-arr', comp: 'el-select', list: 'goodsMallTypes', arrLabel: 'key', arrKey: 'value' },
          { label: '发货日期', value: 'deliverDate', type: 'date', comp: 'el-date-picker', bind: { format: "yyyy-MM-dd", valueFormat: "yyyy-MM-dd" } },
          { label: '原价', value: 'price', type: 'number', comp: 'el-input', },
          { label: '售出价格', value: 'soldPrice', type: 'number', comp: 'el-input' },
          { label: '库存量', value: 'inventory', type: 'number', comp: 'el-input', event: 'showInventory' },
          { label: '售出量', value: 'soldNum', type: 'number', comp: 'el-input', event: 'showSoldNum' },
          { label: '排序', value: 'sort', type: 'number', comp: 'el-input' },
          { label: '玩法说明', value: 'note', type: 'textarea', comp: 'el-input', className: 't-form-block' },
          { label: '商品图片', value: 'goodsImg', slotName: 'uploadFile', fileNumber: 1 },
          { label: '详情图片', value: 'detailsImg', slotName: 'uploadFile', fileNumber: 1 },
          { label: '描述图片', value: 'describeImg', slotName: 'uploadFile', fileNumber: 3 },
        ],
        rules: {
          goodsName: [{ required: true, message: '请输入系列名称', trigger: 'blur' }],
          shelves: [{ required: true, message: '请至少选择一个状态', trigger: 'change' }],
          goodsMallType: [{ required: true, message: '请选择分类', trigger: 'blur' }],
          deliverDate: [{ required: true, message: '请选择发货日期', trigger: 'blur' }],
          price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
          soldPrice: [{ required: true, message: '请输入现价', trigger: 'blur' }],
          inventory: [{ required: true, message: '请输入库存数', trigger: 'blur' }],
          soldNum: [{ required: true, message: '请修改售出数量', trigger: 'blur' }],
        },
        //按钮
        operatorList: [],
        // 相关列表
        listTypeInfo: {
          shelves: this.$store.state.menu.dictList.shelves,
          goodsMallTypes: this.$store.state.menu.dictList.goods_mall_type,
        }
      },
    }
  },
  mounted () { },
  methods: {
    // 初始化
    init (id) {
      this.isDrawer = true
      this.goodId = id
      if (id) {
        this.getDetails(id)
      }
    },
    //关闭弹框
    handleClose () {
      this.isDrawer = false
      this.resetForm()
    },
    //获取详情数据
    getDetails (id) {
      this.$http.get(`/goodsMall/findGoodsMallById?id=${id}`).then(({ data: res }) => {
        let goodData = res.data
        if (goodData.describeImg) {
          goodData.describeImg = goodData.describeImg.split(',')
        }
        this.formOpts.formData = goodData
      })
    },
    // 触发事件
    handleEvent (type, val) {
      switch (type) {
        case 'checkbox':
          console.log(val, type)
          break
        case 'showInventory':
          if (this.formOpts.formData.soldNum < val) {
            this.formOpts.formData.inventory = this.formOpts.formData.soldNum
          }
          break
        case 'showSoldNum':
          if (this.formOpts.formData.inventory < val) {
            this.formOpts.formData.soldNum = this.formOpts.formData.inventory
          }
          break
      }
    },
    // 新增商品
    addDomain () {
      this.formOpts.formData.goodDetailsList.push({
        ductName: '', //商品名称
        gradeConsumeId: '',//实际等级
        referPrice: '', //参考价格(前端显示)
        inventory: '', //	库存
        levelType: '', //前端展示等级
        boxImg: '', //商品图片
      })
    },
    //删除商品
    removeDomain (index) {
      this.formOpts.formData.goodDetailsList.splice(index, 1)
    },
    // 提交form表单
    submitForm () {
      this.formOpts.ref.validate((valid) => {
        if (valid) {
          this.butLoading = true
          let goodData = JSON.parse(JSON.stringify(this.formOpts.formData))
          if (goodData.describeImg) {
            goodData.describeImg = goodData.describeImg.join(',')
          }
          let apiUrl = ''
          if (goodData.id) {    //修改
            apiUrl = '/goodsMall/editGoodsMall'
          } else {   //新增
            apiUrl = '/goodsMall/addGoodsMall'
          }
          this.$http.post(apiUrl, goodData).then(res => {
            this.$message({
              message: goodData.id ? '修改成功' : '新增成功',
              type: 'success'
            })
            this.butLoading = false
            this.$emit('refresh')
            this.handleClose()
          }).catch(err => {
            this.butLoading = false
          })
        }
      })
    },
    // 重置form表单
    resetForm () {
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data.call(this).formOpts.formData
      )
      this.$nextTick(() => {
        this.formOpts.ref.clearValidate()
      })
    },
  }
}
</script>

<style lang="less" scoped>
</style>